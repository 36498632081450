(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/react-help-utils/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/utils/react-help-utils/assets/javascripts/prescript.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.utils = svs.utils || {};
  svs.utils.reactHelpUtils = svs.utils.reactHelpUtils || {};
  svs.utils.reactHelpUtils.components =
    svs.utils.reactHelpUtils.components || {};
})(svs);


 })(window);