(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/react-help-utils/assets/javascripts/wrap-view.js') >= 0) return;  svs.modules.push('/components/utils/react-help-utils/assets/javascripts/wrap-view.js');
"use strict";


(function (svs) {
  'use strict';

  const {
    Component
  } = React;
  svs.utils.reactHelpUtils.wrapView = View => {
    const eventHandlers = {
      add: (model, callback) => {
        model.on('add', event => {
          const key = 'attributes';
          callback(key, event.attributes);
        });
      },
      remove: (model, callback) => {
        model.on('remove', event => {
          const key = 'attributes';
          callback(key, event.attributes);
        });
      },
      change: (model, callback) => {
        model.on('change', event => {
          const changed = event.changed;

          for (const key in changed) {
            if (Object.prototype.hasOwnProperty.call(changed, key)) {
              callback(key, changed[key]);
            }
          }
        });
      }
    };
    const initEventHandlers = (events, model) => {
      for (const key in events) {
        if (Object.prototype.hasOwnProperty.call(events, key) && eventHandlers[key]) {
          const eventHandler = eventHandlers[key];
          const callback = events[key];
          eventHandler(model, callback);
        }
      }
    };
    const isEqual = (nextProps, props) => JSON.stringify(nextProps) === JSON.stringify(props);

    class BackboneWrapper extends Component {
      componentDidMount() {
        const events = this.props.events;
        const options = this.props.options;
        if (events && options) {
          const model = options.itemModel || options.configModel || options.model;
          if (!model) {
            throw new Error('A model is required to handle events');
          }
          initEventHandlers(events, model);
        }
        this.view = options ? new View(options) : new View();
        const $el = $(this.el);
        $el.html(this.view.render().el);
        if (this.view.triggerMethod) {
          this.view.triggerMethod('attach');
          this.view.triggerMethod('show');
        }
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.view.updateOptions) {
          if (!isEqual(nextProps, this.props)) {
            this.view.updateOptions(nextProps.options);
          }
        }
      }
      shouldComponentUpdate() {
        return this.props.shouldUpdate;
      }
      componentWillUnmount() {
        if (this.view.close) {
          this.view.close();
        }
        if (this.view.destroy) {
          this.view.destroy();
        }
      }
      render() {
        return React.createElement("div", {
          className: this.props.className,
          ref: e => {
            this.el = e;
          }
        });
      }
    }
    BackboneWrapper.defaultProps = {
      shouldUpdate: false
    };
    return BackboneWrapper;
  };
})(svs);

 })(window);