(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/react-help-utils/assets/javascripts/create-router.js') >= 0) return;  svs.modules.push('/components/utils/react-help-utils/assets/javascripts/create-router.js');
"use strict";



(function (svs) {
  const {
    isValidElement
  } = React;
  const {
    BrowserRouter,
    Switch,
    Route,
    Router
  } = ReactRouterDOM;
  const {
    baseUrl,
    routes
  } = svs.core.data;
  const {
    Async
  } = svs.utils.reactHelpUtils.components;
  const isPromise = promise => promise && typeof promise.then === 'function'; 

  svs.utils.reactHelpUtils.createRouter = function (routeHandlers) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let historyReference = arguments.length > 2 ? arguments[2] : undefined;
    if (options.useHistory && window.History) {
      const history = window.History.createBrowserHistory({
        basename: baseUrl
      });
      historyReference.history = history;
      return React.createElement(Router, {
        history: history
      }, renderRoutes(routeHandlers, options));
    }
    return React.createElement(BrowserRouter, {
      basename: baseUrl
    }, renderRoutes(routeHandlers, options));
  };
  const renderRoutes = (routeHandlers, options) => React.createElement("div", null, React.createElement(Switch, null, Object.keys(routes).map(path => {
    const clientAction = routes[path];
    const routeHandler = routeHandlers[clientAction];
    if (!routeHandler) {
      throw new Error("".concat(path, " does not have a matching handler for client action: ").concat(clientAction));
    }
    let routeComponent = createRouteComponent(routeHandler);
    if (options.enhance) {
      routeComponent = options.enhance(routeComponent);
    }
    return React.createElement(Route, {
      component: routeComponent,
      exact: true,
      key: path,
      path: path
    });
  })), options.catchAll ? React.createElement(Route, {
    exact: true
  }, options.catchAll) : null);
  function createRouteComponent(routeHandler) {
    return props => {
      const elementOrPromise = routeHandler(props);
      if (isValidElement(elementOrPromise)) {
        return elementOrPromise;
      }
      if (isPromise(elementOrPromise)) {
        return React.createElement(Async, {
          promise: elementOrPromise
        });
      }
      throw new Error('Route handler must return a valid react element or a promise that resolves with one');
    };
  }
})(svs);

 })(window);