(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/react-help-utils/assets/javascripts/components/authenticated.js') >= 0) return;  svs.modules.push('/components/utils/react-help-utils/assets/javascripts/components/authenticated.js');
"use strict";


(function (svs) {
  function AuthenticatedPlayer(props) {
    if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      return null;
    }
    return props.children;
  }
  function AuthenticatedInternal(props) {
    if (!svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL)) {
      return null;
    }
    return props.children;
  }
  function AuthenticatedRetailer(props) {
    if (!svs.core.userSession.hasRole(svs.core.userSession.roles.RETAILER)) {
      return null;
    }
    return props.children;
  }
  function AuthenticatedCustomerService(props) {
    if (!svs.core.userSession.hasRole(svs.core.userSession.roles.CUSTOMERSERVICE)) {
      return null;
    }
    return props.children;
  }
  function HasRole(props) {
    if (!svs.core.userSession.hasRole(props.role)) {
      return null;
    }
    return props.children;
  }
  svs.utils.reactHelpUtils.components.AuthenticatedPlayer = AuthenticatedPlayer;
  svs.utils.reactHelpUtils.components.AuthenticatedInternal = AuthenticatedInternal;
  svs.utils.reactHelpUtils.components.AuthenticatedRetailer = AuthenticatedRetailer;
  svs.utils.reactHelpUtils.components.AuthenticatedCustomerService = AuthenticatedCustomerService;
  svs.utils.reactHelpUtils.components.HasRole = HasRole;
})(svs);

 })(window);