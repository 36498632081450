(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/react-help-utils/assets/javascripts/create-store.js') >= 0) return;  svs.modules.push('/components/utils/react-help-utils/assets/javascripts/create-store.js');
"use strict";



(function (svs) {
  const {
    createStore,
    combineReducers,
    applyMiddleware,
    compose
  } = RTK;
  let composeEnhancers = compose;

  if (svs.core.config.data.svsconfig.env !== 'production') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
  svs.utils.reactHelpUtils.createStore = function (reducers) {
    let initialState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let middleware = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let reducer;
    const enhancer = applyMiddleware(ReduxThunk.default, ...middleware);
    if (typeof reducers === 'function') {
      reducer = reducers;
    } else {
      reducer = combineReducers(reducers);
    }
    const store = createStore(reducer, initialState, composeEnhancers(enhancer));

    svs.utils.reactHelpUtils.store = store;
    return store;
  };
})(svs);

 })(window);